import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';
import Home from '../routes/home';
import Basic from '../routes/basic';
import Income from '../routes/income';
import Deduction from '../routes/deduction';
import TaxCredit from '../routes/taxcredit';
import Analysis from '../routes/analysis';

import './styles.css';


const App = () => {

    return (
        <div id="app">
            <Header />
            <Router>
                <Route path="/" component={Home} />
                <Route path="/basic/" component={Basic} />
                <Route path="/income/" component={Income} />
                <Route path="/deduction/" component={Deduction} />
                <Route path="/taxcredit/" component={TaxCredit} />
                <Route path="/analysis/" component={Analysis} />
            </Router>
        </div>
)};

export default App;
